const BASE_COLORS = {
    BACKGROUND_LIGHT_RED: '#c21b170f',
    BACKGROUND_LIGHT_GRAY: '#D9E0E5',
    TEXT_BLACK: '#001B33',
    TEXT_BLUE: '#0085FF',
    PRIMARY_MAIN: '#c21b17',
    SECONDARY_MAIN: '#094E41',
    BACKGROUND_DEFAULT: '#e5e5e5',
    WHITE: '#ffffff',
    GRAY: '#949494',
};

const WHITE_LABEL_BASE_COLORS = {
    BACKGROUND_LIGHT_RED: '#bec8d1',
    BACKGROUND_LIGHT_GRAY: '#D9E0E5',
    TEXT_BLACK: '#001B33',
    TEXT_BLUE: '#0085FF',
    PRIMARY_MAIN: '#1976d2',
    SECONDARY_MAIN: '#094E41',
    BACKGROUND_DEFAULT: '#e5e5e5',
    WHITE: '#ffffff',
    GRAY: '#949494',
};

const FROST_DAMAGE_COLORS = {
    1: '#FDB77A',
    2: '#EB6040',
    3: '#B30000',
};

const CROP_COLORS = {
    1: [241, 198, 0, 0.5],
    2: [118, 234, 182, 0.5],
    3: [147, 234, 118, 0.5],
    4: [0, 202, 137, 0.5],
};

const ZONE_CATEGORY_COLORS = {
    0: '#D7191C',
    1: '#EB7310',
    2: '#DBC810',
    3: '#99E630',
    4: '#3CBF58',
    5: '#00723F',
};

const YIELD_COLORS = {
    0: 'rgba(255, 0, 0, 0.8)',
    2: 'rgba(255, 49, 0, 0.8)',
    4: 'rgba(255, 97, 0, 0.8)',
    6: 'rgba(255, 146, 0, 0.8)',
    8: 'rgba(255, 194, 0, 0.8)',
    10: 'rgba(255, 243, 0, 0.8)',
    12: 'rgba(219, 255, 0, 0.8)',
    14: 'rgba(170, 255, 0, 0.8)',
    16: 'rgba(121, 255, 0, 0.8)',
    18: 'rgba(73, 255, 0, 0.8)',
    20: 'rgba(24, 255, 0, 0.8)',
    22: 'rgba(0, 255, 0, 0.8)',
};

export {
    BASE_COLORS,
    CROP_COLORS,
    FROST_DAMAGE_COLORS,
    WHITE_LABEL_BASE_COLORS,
    YIELD_COLORS,
    ZONE_CATEGORY_COLORS,
};
